import React, { useCallback, useState } from "react";
import Gallery from "react-photo-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

const photos = [...Array(60).keys()].map((i) => {
  return { src: `House_${i}.jpg`, width: 9, height: 6 };
});
photos[23].width = 6;
photos[38].width = 6;

photos[23].height = 9;
photos[38].height = 9;
console.log(photos);

export default function PhotoGallery() {
  console.log("Rendering");
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  return (
    <div>
      <Gallery
        photos={photos}
        targetRowHeight={600}
        onClick={(e, meta) => {
          setPhotoIndex(meta.index);
          setLightBoxOpen(true);
        }}
      />
      {lightBoxOpen && (
        <Lightbox
          mainSrc={photos[photoIndex].src}
          nextSrc={photos[(photoIndex + 1) % photos.length].src}
          prevSrc={photos[(photoIndex + photos.length - 1) % photos.length].src}
          onCloseRequest={() => setLightBoxOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % photos.length)
          }
        />
      )}
    </div>
  );
}
